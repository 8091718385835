<template>
  <div id="investmentCalculator-component" class="mdc-layout-grid__inner">
    <div v-if="modal" class="modal">      
      <div class="text-modal">
        <span class="material-icons-outlined" @click="_closePopup()" style="font-size: 17px; float:right; margin: 3px 5px 0px 0px; cursor:pointer;">close</span>        
        <p style="margin-top:20px">¿Querría recibir información sobre productos de eurekers?</p>        
        <div v-for="(iItem, itemIndex) in input_capital" :key="itemIndex" class="center-form">
          <div v-if="iItem.type == 'datos'">
            <InputTextOutline v-for="(iInput, inputIndex) in iItem.componentsConfig" :key="inputIndex" :input_label="iInput.input_label"  :has_focus="iInput.has_focus" :icon_class="iInput.icon_class" :input_key_to_save_user_values="iInput.input_key_to_save_user_values" :input_class_character_counter="iInput.input_class_character_counter" :permission="iInput.permission" :icon_permission="iInput.icon_permission" :character_counter_permission="iInput.character_counter_permission" :helper_permission="iInput.helper_permission" :test_field_is_numeric="iInput.test_field_is_numeric" :length="iInput.length" :is_input_textarea="iInput.is_input_textarea" @_emit_method_order_input="_getDatos" />
          </div>          
        </div>
        <Button class="button-calculate" @_emit_method_button="_sendDatos()" :button_name="button_name1" :button_key="button_key1" :button_icon="button_icon1" :icon_permission="false" />        
      </div>
    </div>
    <div class="mdc-layout-grid__cell--span-4 right">
      <div class="investment-wrapper">
        <h3 id="inves-calc-title" class="typo-headline-6">Calculadora de rentabilidad</h3>

        <p class="typo-body-2">Herramienta de inversión para calcular la rentabilidad de una inversión.</p>
        <p class="investment-text margin-bottom"></p>
        <div class="center-container">        
          <div v-for="(iItem, itemIndex) in input_capital" :key="itemIndex" class="center-container">
            <div v-if="iItem.type == 'input'" class="center-container-inputs" >
              <InputTextOutline v-for="(iInput, inputIndex) in iItem.componentsConfig" :key="inputIndex" :input_label="iInput.input_label"  :has_focus="iInput.has_focus" :icon_class="iInput.icon_class" :input_key_to_save_user_values="iInput.input_key_to_save_user_values" :input_class_character_counter="iInput.input_class_character_counter" :permission="iInput.permission" :icon_permission="iInput.icon_permission" :character_counter_permission="iInput.character_counter_permission" :helper_permission="iInput.helper_permission" :test_field_is_numeric="iInput.test_field_is_numeric" :length="iInput.length" :is_input_textarea="iInput.is_input_textarea" @_emit_method_order_input="_getValueFromInputComponent" />
            </div>
            </div>
            <div class="center-container-inputs">
                <InputTextOutline :input_label="input_capital[1].componentsConfig[0].input_label" :has_focus="input_capital[1].componentsConfig[0].has_focus" :icon_class="input_capital[1].componentsConfig[0].icon_class" :input_key_to_save_user_values="input_capital[1].componentsConfig[0].input_key_to_save_user_values" :input_class_character_counter="input_capital[1].componentsConfig[0].input_class_character_counter" :permission="input_capital[1].componentsConfig[0].permission" :icon_permission="input_capital[1].componentsConfig[0].icon_permission" :character_counter_permission="input_capital[1].componentsConfig[0].character_counter_permission" :helper_permission="input_capital[1].componentsConfig[0].helper_permission" :test_field_is_numeric="input_capital[1].componentsConfig[0].test_field_is_numeric" :length="input_capital[1].componentsConfig[0].length" :is_input_textarea="input_capital[1].componentsConfig[0].is_input_textarea" @_emit_method_order_input="_getValueFromInputComponent" />
                <SelectOutline class="center-container-inputs-meses" :label_name="input_capital[2].componentsConfig[0].label_name" :options="input_capital[2].componentsConfig[0].options" :helper_select_permission="input_capital[2].componentsConfig[0].helper_select_permission" :default_value="input_capital[2].componentsConfig[0].default_value" :component_class="input_capital[2].componentsConfig[0].component_class" @_emit_method_order_select="_getValueFromSelectComponent" />    
            </div>
          <Button class="button-calculate" @_emit_method_button="_pushPopUp()" :button_name="button_name" :button_key="button_key" :button_icon="button_icon" :icon_permission="false" />
        </div>
      </div>
    </div>
    <div class="mdc-layout-grid__cell--span-4 right">
      <div class="investment-wrapper wrapper-background">
            <p>La inversión original de {{ valor_inicial_calculated }}€ se ha convertido en {{ valor_final_calculated }}€ en {{this.años_calculated }} años {{this.meses_calculated }} meses.</p><br>
            <p>Tu RSI simple anualizado es: </p>
            <p class="investment-title"> <span class="text-black result">{{ resultado }} %</span></p>
            <br>
            <p>Y el ROI anualizado compuesto es: </p>
            <span class="investment-title"> <span class="text-black result">  {{   resultadoROI  }} % </span></span> 
      </div>
    </div>
    <div class="mdc-layout-grid__cell--span-2 left"></div>
    <div class="mdc-layout-grid__cell--span-2 left"></div>
    <div class="mdc-layout-grid__cell--span-8 right">
      <figure class="highcharts-figure">
          <div id="container"></div>
          <p class="highcharts-description">
          </p>
      </figure>
    </div>
  </div>
</template>

<script>
import Button from '../components/helpers/Button.vue';
import SelectOutline from "@/components/helpers/SelectOutline";
import InputTextOutline from '@/components/helpers/InputTextOutline.vue';
import inputCapital from './configs/InvestmentCalculator/input_inversion.json';
import Highcharts from "highcharts";
import Vue from 'vue';

import { mapGetters } from "vuex";

import APICaller from "@/components/codeComponents/APICaller.js";

export default {
  mixins:[APICaller],
  name: 'InvestmentCalculator',

  metaInfo() {
    return {
      title: 'Calculadora de rentabilidad Eurekers',
      meta:[
        { name: 'description', content: "La calculadora de rentabilidad permite conocer cuánto tardarás en alcanzar tu objetivo de inversión y el % de rentabilidad anual necesario, ofrecida por Eurekers"}
      ]
    }
  },

  components: {
    InputTextOutline,
    Button,
    SelectOutline,
  },
  data() {
    return {
      input_capital : inputCapital,
      valor_inicial : 0,
      valor_final : 0,
      años : 0 ,
      meses : 0,
      valor_inicial_calculated : 0,
      valor_final_calculated : 0,
      años_calculated : 0 ,
      meses_calculated : 0,
      diasTotales : 0,
      resultado: 0,
      resultadoROI: 0,
      rsi: 0,
      contador: 0,
      modal: false,
      telefono: "",
      email: "",
      categories: [],
      values: [
        {
            name: 'Beneficio inversión',
            data: [],
            stack: 'inversion'
          }, {
            name: 'Inversión original',
            data: [],
            stack: 'inversion'
          }
      ],

      button_name: "Calcular",
      button_icon: "none",
      button_key: "button_key_calculate",

      button_name1: "Enviar",
      button_icon1: "none",
      button_key1: "button_key_enviar",

      
    };
  },
  mounted() {
    this.telefono = this._getDatosFromCookies("telefono");
    this.email = this._getDatosFromCookies("email");
    this._itsIframe();
  },

  computed: {
    ...mapGetters(['is_mobile_device']),
  },

  methods: {
    _getDatosFromCookies:function(cname){
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(';');
      for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    _itsIframe: function(){
      var URLactual = window.location;
      if(URLactual.search == "?=iframe"){
        let sidebar = document.getElementById("sidebar-nav-container");
        sidebar.style.display = "none";
      }
    },
    _getDatos(value){
      var component = value[1];
      if(component == "telefono"){
        this.telefono = value[0];
      }
      else{
        this.email = value[0];
      }
    },
    //Obtener inputs
    _getValueFromInputComponent(value) {
      var component = value[1];
      if(component == "inversion-original"){
        this.valor_inicial = parseFloat(value[0]);
      }else if(component == "valorFinal"){
        this.valor_final = parseFloat(value[0]);
      }else if(component == "años"){
        this.años = parseFloat(value[0]);
      }
    },
    //Obtener valor del select meses
    _getValueFromSelectComponent: function(value) {
      this.meses = parseInt(value[0]);
    },
    //lanza popup en caso de darle tres veces a calcular
    _pushPopUp(){
      if((this.contador == 2 || this.contador == 9) && (this.telefono == "" && this.email == "")){
        this.modal = true;
      }      
      this._testData();
    },
    _sendDatos: function(){
      let te = /^\d{9}$/;
      let em = /^[a-za-z0-9.!#$%&'+/=?^_`{|}~-]+@[a-za-z0-9-]+(?:\.[a-za-z0-9-]+)*$/;
      if(te.test(this.telefono) && em.test(this.email)){
        document.cookie = "telefono=" + this.telefono + ";";
        document.cookie = "email=" + this.email + ";";

        let data = new URLSearchParams();
        data.append('email', this.email);
        data.append('tlf', this.telefono);

        this._postInfo(data);

      }
      else {
         this.$vueOnToast.pop('error', 'Introduzca datos validos');
      }
    },

    _postInfo(data){
      let success = response => {
        if(response.status == 200){
          this._closePopup();
        } else{
          this.$vueOnToast.pop('error', 'Ha ocurrido un error. Por favor, inténtelo de nuevo.');
        }
      };

      let url = "/calculadora/datos"

      const successHandler = new APICaller.SuccessHandler(success);
      const failureHandler = new APICaller.FailureHandler('296', 'Error en la calculadora.');

      this._postAPICall(url, data, successHandler, failureHandler);

    },

    _closePopup: function(){
      this.modal = false;
    },
    //Comprueba los valores introducidos antes de operar
    _testData(){
      if(!isNaN(this.valor_inicial) && !isNaN(this.valor_final)){
        if(this.valor_inicial > 0 && this.valor_final > 0){
          if(this.años > 0 || this.meses > 0 || (this.años == '' && this.meses != 0)){
            if(Number.isInteger(this.años)){
              if(this.años <= 100){
                this.valor_inicial_calculated = this.valor_inicial;
                this.valor_final_calculated = this.valor_final;
                this.años_calculated = this.años;
                this.meses_calculated = this.meses;
                this._calculateRSI();
                this._calculateROI();
                this.contador++;
              }
              else{
                this.$vueOnToast.pop('error', 'Introduzca años entre 0 y 100');
              }              
            }
            else{
              this.$vueOnToast.pop('error', 'Introduzca años sin decimales, utilice los meses para ello');
            }       
          }
          else{
            this.$vueOnToast.pop('error', 'Introduzca una duración para la inversión');
          }
        }
        else{
          this.$vueOnToast.pop('error', 'Introduzca una inversión');
        }
      }
      else {
        this.$vueOnToast.pop('error', 'Introduzca valores númericos');
      }

      if(this.is_mobile_device == true){
        setTimeout(() => {
          window.scrollTo(0, document.body.scrollHeight);
        }, 1000);
      }

    },
    //Calcula el valor final RSI
    _calculateRSI(){
      this.categories = []
      this.values[0].data = []
      this.values[1].data = []
      var dif = parseFloat(this.valor_final) - parseFloat(this.valor_inicial);
      var rent = dif / this.valor_inicial;
      this.rsi = rent * (365 / this._TimetoDays());
      if(Number.isInteger(this.rsi)) {this.resultado = (this.rsi * 100)}
      else {this.resultado = parseFloat((this.rsi * 100).toFixed(3))}
      this._prepareDataToHighchart();
    },
    _calculateROI(){
      var roi = (this.valor_final - this.valor_inicial) / this.valor_inicial;
      this.resultadoROI = parseFloat(((Math.pow((1 + roi),1/(this.años + this.meses / 12)) -1) * 100).toFixed(3));
    },
    //Pasa todo a dias para realizar la fórmula
    _TimetoDays(){
      if(this.años != ''){
        this.diasTotales = this.años * 365 + (this.meses / 12) * 365;
      }else{
        this.diasTotales =(this.meses / 12) * 365;
      }      
      return this.diasTotales;
    },    
    //calcula el beneficio dependiendo del parámetro n
    _calculateBenefit(n){
      var exp = this._TimetoDays() / 365;
      var raiz = this.valor_final / this.valor_inicial;
      var ra = Math.pow(raiz , 1 / exp) - 1;
      return (this.valor_inicial * Math.pow(1 + ra, n)) - this.valor_inicial;
    },
    //Prepara la información para el gráfico
    _prepareDataToHighchart: function(){
        if(this.resultado < 0){
          this.values[0].name = "Pérdidas de la inversión";
          for(var k=1; k<= this.años ; k++){
            k == 1 ? this.categories.push(k + ' año') : this.categories.push(k + ' años');
            let neg = (parseFloat((this._calculateBenefit(k)).toFixed(2))*-1);         
            this.values[0].data.push(neg);
            this.values[1].data.push(this.valor_inicial - neg);
          }
          if(this.meses != 0 && this.años == ''){
            for(var l=1; l<= this.meses ; l++){
              var sum_mes1 = l / 12;
              l == 1 ? this.categories.push(l + ' mes') : this.categories.push(l + ' meses');
              let neg = (parseFloat((this._calculateBenefit(sum_mes1)).toFixed(2))*-1);          
              this.values[0].data.push(neg);
              this.values[1].data.push(this.valor_inicial - neg);
            }
          }
          else if(this.meses != 0){
            var sum_mes2 = this.años + this.meses / 12;
            this.values[0].data.push(parseFloat(this._calculateBenefit(sum_mes2).toFixed(2)));
            let neg = (parseFloat((this._calculateBenefit(sum_mes2)).toFixed(2))*-1);
            this.values[1].data.push(this.valor_inicial - neg);
            this.categories.push(parseFloat(sum_mes2.toFixed(2)) + ' años');
          }
        }
        else{
          for(var i=1; i<= this.años ; i++){
            i == 1 ? this.categories.push(i + ' año') : this.categories.push(i + ' años');         
            this.values[0].data.push(parseFloat((this._calculateBenefit(i)).toFixed(2)));
            this.values[1].data.push(this.valor_inicial);
          }
          if(this.meses != 0 && this.años == ''){
            for(var j=1; j<= this.meses ; j++){
              var sum_mes = j / 12;
              j == 1 ? this.categories.push(j + ' mes') : this.categories.push(j + ' meses');         
              this.values[0].data.push(parseFloat((this._calculateBenefit(sum_mes)).toFixed(2)));
              this.values[1].data.push(this.valor_inicial);
            }
          }
          else if(this.meses != 0){
            var sum = this.años + this.meses / 12;
            this.values[0].data.push(parseFloat(this._calculateBenefit(sum).toFixed(2)));
            this.values[1].data.push(this.valor_inicial);
            this.categories.push(parseFloat(sum.toFixed(2)) + ' años');
          }
        }
      this._addDataToHighchart();
    },
    _addDataToHighchart: function(){
      let categories = this.categories;
      let values = this.values;
      Vue.nextTick(function() {
        
        Highcharts.chart('container',{
          chart: {
            type: 'column',
          },

          title: {
            text: ''
          },

          colors: ['#00ADD9', '#0F0F1A'],

          xAxis: {categories},

          yAxis: {
            allowDecimals: false,
            min: 0,
            title: {
              text: ''
            }
          },

          tooltip: {
            formatter: function () {
              return '<b>' + this.x + '</b><br/>' +
              this.series.name + ': ' + this.y + '<br/>' +
              'Total: ' + this.point.stackTotal;
            }
          },

          plotOptions: {
            column: {
              stacking: 'normal'
            }
          },

          series: values
        });
      })
    }
  }
}
</script>




<style lang="scss" scoped>
  @use "@material/switch";
  @include switch.core-styles;

  .mdc-switch {
    @include switch.toggled-on-color(rgba(0, 0, 0, 0.6));
    @include switch.toggled-off-color(rgba(0, 0, 0, 0.2));
  }

  $color_black: #00000B;
  $color_dark: #000010;
  $color_grey: rgba(0, 0, 0, 0.6);
  $back_grey: #F3F4F7;

  .investment-wrapper {
    margin-top: 175px;}
  p {
    margin: 0;}
  hr{
    border-top: 1px rgba(46, 45, 44, 0.08) solid;
    margin: 30px 20px;}
  .center-container{
    text-align: left;
  }
  .investment-title {
    color: $color_dark;
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;}
  .investment-text {
    color: $color_dark;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;}
  .investment-ipo {
    margin: 30px 0;
    display: flex;
    align-items: center;}
  .investment-result {
    margin: 0 20px;}
  .investment-grey {
    color: $color_grey;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;}
  .investment-black {
    margin-left: 60px;
    color: $color_black;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.15px;}
  .margin-right {
    margin-right: 12px;}
  .margin-left {
    margin-left: 12px;}
  .margin-bottom {
    margin-bottom: 30px;}
  .input-container-size {
    width: 41%;
    margin-top: 20px;
  }
  .wrapper-background{
    background-color:var(--color-background);
    color: var(--color-text-primary);
    padding : 5%;
    padding-top: 50px;
    margin-top:230px;
  }
  .result{
    font-size: 22px;
    color: var(--color-text-primary);
    margin-top: 25px;
  }
  .button-calculate{
    margin-top: 25px;
    background-color: hsl(195deg 95% 41%);
    font-size: 12px;
    font-weight: bolder;
  }
  .div-meses{
    margin-top: 25px;
    text-align: left;
    max-width: 60%;
  }

  @media(max-width: 400px){
    #investmentCalculator-component{
      padding: 10px;
    }
  }
  @media (min-width: 600px) and (max-width: 839px) {
    .mdc-layout-grid__cell--span-4.right {
      grid-column-end: span 8;
      margin: 0;}
    .input-container-size {
      max-width: 50%;}
  }
  @media (min-width: 840px){
    .mdc-layout-grid__cell--span-8.left {
      grid-column-end: span 6;
      margin: 0;
      height: 100vh;
      background-color: $back_grey; }
    .mdc-layout-grid__cell--span-4.right {
      grid-column-end: span 6;
      margin-right: 36px;}
    #investmentCalculator-component {
      margin: 50px 5%;
      margin-top: 0;
    }
    .investment-wrapper {
      margin-top: 0px;
    }
    #inves-calc-title {
      margin-top: 50px;
    }
  }
  @media (min-width: 1280px){
    .mdc-layout-grid__cell--span-8.left {
      grid-column-end: span 8;}
    .mdc-layout-grid__cell--span-4.right {
      grid-column-end: span 4;}
  }
  @media (max-width: 840px){
    .investment-wrapper {
      margin-top: 50px !important;
    }
  }
  .highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 1200px;
    margin: 1em auto;
}

#container {
    height: 100%;
    width:100%;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
.modal{
  position: fixed;
  top: 0px;
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  z-index: 999999;
  left: 0px;
}
.text-modal{ 
  position:absolute;
  background-color:white;
  color: black;
  display:block;
  top: 30%;
  left: 35%;
  height: 35%;
  width: 33%;
  border: 1px solid black;
  border-radius: 30px;
  padding: 10px;
}
.center-container-inputs{
  display:flex;
  justify-content: space-between;
}
.center-container-inputs-años{
  width:200px !important;
  display:flex;
  width:100% !important;
}
.center-container-inputs-meses{
  margin-top:20px;
  width: 41%
}
p{
  font-size: 15px;
}
.center-form{
  display:block;
}
@media (max-width: 840px) {
  #investmentCalculator-component {
    width: 90%;
    margin: auto;
    margin-top: 50px;
  }

  .investment-wrapper {
    margin-top: 0px !important;
  }

  #inves-calc-title {
    margin-top: 0px;
  }
}

h3, p {
  color: var(--color-text-primary);
}
</style>
